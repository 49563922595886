<template>
  <div class="container-fluid bg-light">

      <div class="row mb-3">
        <div class="col-md-10 mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">


              <div class="row mb-3">
                <div class="col-auto my-auto">
                  <h5 class="mb-0 title">View order</h5>
                </div>

                <div class="col text-right">
                  <button
                    class="btn btn-sm btn-outline-primary mb-2 mb-md-0"
                    v-if="
                      client.clinic.allow_client_invoice_download &&
                      order.status !== 'cancelled'
                    "
                    @click="viewInvoice(order)"
                  >
                    <i class="fad fa-download mr-1" />
                    Invoice
                  </button>
                  <button
                    class="btn btn-sm btn-primary mb-2 mb-md-0 ml-2"
                    @click="makePayment(order)"
                    v-if="order.status !== 'paid' && order.status !== 'cancelled'"
                  >
                    <i class="fad fa-credit-card mr-1" />
                    Payment
                  </button>
                </div>

              </div>

              <hr class="mt-2 mb-4" />

              <div v-if="!loading" class="row mb-4">
                <div class="col">
                  <div class="card text-center border-0 bg-light">
                    <div class="card-body">
                      <p class="fw-bold text-uppercase mt-2">
                        Client
                      </p>
                      <p>{{ order.client.name }}</p>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card text-center border-0 bg-light">
                      <div class="card-body">
                        <p class="fw-bold text-uppercase mt-2">
                          Fulfillment
                        </p>
                        <p v-if="!order.supplier_id" class="text-capitalize">
                          {{ order.clinic.name | formatText }}
                        </p>
                        <p v-if="order.supplier_id" class="text-capitalize">
                          {{ order.supplier.name | formatText }}
                        </p>
                      </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card text-center border-0 bg-light">
                    <div class="card-body">
                      <p class="fw-bold text-uppercase mt-2">
                        Status
                      </p>
                      <p class="text-capitalize">
                        {{
                          order.status === 'paid' && order.api_status && order.api_status !== 'new'
                            ? order.api_status : order.status | formatText
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card text-center border-0 bg-light">
                    <div class="card-body">
                      <p class="fw-bold text-uppercase mt-2">
                        Total
                      </p>
                      <p>{{order.price_display ? order.price_display : '-'}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card border-0" v-if="!loading">
                <div class="card-header">
                  <h5 class="mb-0 mt-1">Order Items</h5>
                </div>
                <div class="card-body pt-1">
                  <div class="row">
                    <div class="col">
                      <table class="table my-2">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Brand</th>
                            <th scope="col">SKU</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Cost</th>
                            <th scope="col">VAT</th>
                            <th scope="col">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in order.items" :key="item.id">
                            <td>
                              {{ item.name }}<br/>
                              <small>{{ item.comments }}</small>
                            </td>
                            <td style="width: 10%;">{{ item.product && item.product.model && item.product.model.brand ? item.product.model.brand : "-" }}</td>
                            <td style="width: 10%;">{{ item.product && item.product.model && item.product.model.sku ? item.product.model.sku : "-" }}</td>
                            <td>{{ item.quantity }}</td>
                            <td  style="width: 10%;">
                              <span v-if="order.currency">{{ order.currency.symbol }}</span>
                              {{ parseFloat(item.retail - item.retail_vat_included).toFixed(2) }}
                            </td>
                            <td style="width: 10%;">
                              <span v-if="order.currency">{{ order.currency.symbol }}</span>
                              {{ item.retail_vat_included }}
                            </td>
                            <td style="width: 10%;">
                              <span v-if="order.currency">{{ order.currency.symbol }}</span>
                              {{ item.retail }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card border-0 mb-3" v-if="!loading && order.discounts.length > 0">
                <div class="card-header">
                  <h5 class="mb-0 mt-1">Discount Codes</h5>
                </div>
                <div class="card-body pt-1">
                  <table class="table">
                    <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">Discount Rate</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="d in order.discounts" :key="d.id">
                      <td>{{ d.code.code }}</td>
                      <td>{{ d.code.discount_percent }}%</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="card border-0 mb-3" v-if="!loading && order.shipping_address">
                <div class="card-header">
                  <h5 class="mb-0 mt-1">Shipping Address</h5>
                </div>
                <div class="card-body pt-1">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="bg-light p-3">
                        <h5
                            v-clipboard:copy="order.shipping_address.label"
                            v-tooltip.bottom="'Click to copy'"
                            style="cursor: pointer"
                        >
                          {{ order.shipping_address.label }}
                        </h5>
                        <p
                            class="mb-1"
                            v-if="order.shipping_address.address_1"
                            v-clipboard:copy="order.shipping_address.address_1"
                            v-tooltip.bottom="'Click to copy'"
                            style="cursor: pointer"
                        >
                          {{ order.shipping_address.address_1 }}
                        </p>
                        <p
                            class="mb-1"
                            v-if="order.shipping_address.address_2"
                            v-clipboard:copy="order.shipping_address.address_2"
                            v-tooltip.bottom="'Click to copy'"
                            style="cursor: pointer"
                        >
                          {{ order.shipping_address.address_2 }}
                        </p>
                        <p
                            class="mb-1"
                            v-if="order.shipping_address.town"
                            v-clipboard:copy="order.shipping_address.town"
                            v-tooltip.bottom="'Click to copy'"
                            style="cursor: pointer"
                        >
                          {{ order.shipping_address.town }}
                        </p>
                        <p
                            class="mb-1"
                            v-if="order.shipping_address.city"
                            v-clipboard:copy="order.shipping_address.city"
                            v-tooltip.bottom="'Click to copy'"
                            style="cursor: pointer"
                        >
                          {{ order.shipping_address.city }}
                        </p>
                        <p
                            class="mb-1"
                            v-if="order.shipping_address.county"
                            v-clipboard:copy="order.shipping_address.county"
                            v-tooltip.bottom="'Click to copy'"
                            style="cursor: pointer"
                        >
                          {{ order.shipping_address.county }}
                        </p>
                        <p
                            class="mb-1"
                            v-if="order.shipping_address.post_code"
                            v-clipboard:copy="order.shipping_address.post_code"
                            v-tooltip.bottom="'Click to copy'"
                            style="cursor: pointer"
                        >
                          {{ order.shipping_address.post_code }}
                        </p>
                        <p class="mb-1" v-if="order.shipping_address.country">
                          {{ order.shipping_address.country }}
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <loading v-if="loading" class="my-4" />
                </div>
              </div>

              <div v-if="!loading" class="row pagination">
                <div class="col">
                  <button
                    type="button"
                    @click.stop="backToOrders()"
                    class="btn btn-sm btn-outline-secondary btn-back me-2">
                    <i class="fad fa-arrow-left me-1"></i>
                    Back
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      loading: true,
      order: [],
      pagination: [],
      searchField: "id",
      searchParam: "",
      searchTerm: "",
      sortDirection: "desc",
      sortField: null
    }
  },
  methods: {
    backToOrders() {
      this.$router.push('/orders');
    },
    fetchOrderBill() {
      var orderId = this.$route.params.id;
      var url = process.env.VUE_APP_API_URL + "/client/orders/" + orderId;

      this.loading = true;

      this.$axios.get(url).then(({ data }) => {
        this.order = data;
        this.loading = false;
      });
    },
    makePayment(order) {
      this.$router.push("/orders/" + order.id + "/card-payment");
    },
    viewInvoice(order) {
      this.$router.push("/orders/" + order.id + "/invoice");
    },
  },
  filters: {
    formatDateTime(date) {
      return moment(date).format("LLL");
    },
    formatText(str) {
      return str ? str.replace(/_/g, " ") : '-';
    },
  },
  computed: {
    client() {
      return this.$store.user;
    },
  },
  mounted() {
    this.fetchOrderBill();
  },
  components: {
    Loading
  }
}
</script>

<style>
  .title {
    font-size: 1.125rem;
  }

  .table thead > tr > th {
    border-top: 0px;
    border-bottom: 1px;
  }
</style>